import { useRef } from 'react'

import { NavLink, Link } from 'react-router-dom'

import { menuOptions } from '../../db'

export default function Navbar() {
  const navResponsive = useRef()

  const handleOpen = () => {
    navResponsive.current.classList.remove('navbar-close')
    navResponsive.current.classList.add('navbar-open')
  }

  const handleClose = () => {
    navResponsive.current.classList.remove('navbar-open')
    navResponsive.current.classList.add('navbar-close')
  }

  return (
    <nav className="max-w-7xl mx-auto px-4 py-4">
      <div className="flex items-center justify-between">
        <div className="flex-shrink-0">
          <Link to="/">
            <img className="logo" src="/images/logo-sm.png" alt="Logo Icassh Propiedades" width="100" height="auto" />
          </Link>
        </div>

        <div className="hidden lg:block">
          <div className="flex items-center space-x-3">
            {menuOptions.map((option) => (
              <NavLink
                exact
                key={option.id}
                to={option.url}
                className="text-lg p-2 rounded-lg font-bold text-white text-shadow-nav transition-all duration-300 hover:bg-red-400 hover:bg-opacity-60"
                activeClassName="bg-red-400 bg-opacity-60"
              >
                {option.name}
              </NavLink>
            ))}
            <a
              href={process.env.REACT_APP_CONDOMINIO_PRO}
              target="_blank"
              rel="noopenner noreferrer nofollow"
              className="text-lg p-2 rounded-lg font-bold text-white text-shadow-nav transition-all duration-300 hover:bg-red-400 hover:bg-opacity-60"
            >
              Comunidad
            </a>
          </div>
        </div>

        <div onClick={handleOpen} className="lg:hidden cursor-pointer">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-500 bg-gray-800 bg-opacity-40 rounded"
            width="44"
            height="44"
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <line x1="4" y1="6" x2="20" y2="6" />
            <line x1="4" y1="12" x2="20" y2="12" />
            <line x1="4" y1="18" x2="20" y2="18" />
          </svg>
        </div>

        <div
          ref={navResponsive}
          className="bg-gradient-to-b from-red-100 to-white rounded-tl-3xl rounded-bl-3xl navbar"
        >
          <div className="p-4">
            <div onClick={handleClose} className="cursor-pointer mb-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current text-red-400 w-full mx-auto"
                width="44"
                height="44"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <line x1="18" y1="6" x2="6" y2="18" />
                <line x1="6" y1="6" x2="18" y2="18" />
              </svg>
            </div>
          </div>

          <div className="p-4 text-center">
            {menuOptions.map((option) => (
              <NavLink
                exact
                key={option.id}
                to={option.url}
                className="block font-bold text-xl py-3 text-gray-600 border-b border-red-200"
                activeClassName="text-red-400"
              >
                {option.name}
              </NavLink>
            ))}
            <a
              href={process.env.REACT_APP_CONDOMINIO_PRO}
              target="_blank"
              rel="noopenner noreferrer nofollow"
              className="block font-bold text-xl py-3 text-gray-600 border-b border-red-200"
            >
              Comunidad
            </a>
          </div>
        </div>
      </div>
    </nav>
  )
}
