import { useEffect, useState, useRef } from 'react'

import { toast } from 'material-react-toastify'
import { useFormik } from 'formik'
import * as yup from 'yup'
import ReCAPTCHA from 'react-google-recaptcha'

import { avatars } from '../db'
import { sendFormContact, getPropertiesTitleApi } from '../helpers/fetch'
import { ToastError, ToastSuccess } from '../components/ui/ToastMessage'
import Page from './Page'
import Seo from './Seo'

const INITIAL_VALUE = {
  name: '',
  email: '',
  phone: '',
  property: '',
  message: '',
  recaptcha: ''
}

const VALIDATION_SCHEMA = {
  name: yup.string().trim().required('Su nombre es necesario.'),
  email: yup.string().trim().email('Ingrese un email válido por favor.').required('Su email es necesario.'),
  phone: yup
    .string()
    .matches(/^[0-9]*$/, 'Ingrese solo números por favor.')
    .required('Su teléfono es necesario.'),
  property: yup.string(),
  message: yup.string().trim().required('Su mensaje es importante.'),
  recaptcha: yup.string().required(true)
}

export default function Contacto() {
  const recaptchaRef = useRef()
  const [propertiesTitle, setPropertiesTitle] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingProperties, setLoadingProperties] = useState(false)

  // Rellenando el select con los títulos de propiedades
  useEffect(() => {
    const getProperties = async () => {
      setLoadingProperties(true)
      const propertiesTitle = await getPropertiesTitleApi()
      setPropertiesTitle(propertiesTitle)
      setLoadingProperties(false)
    }
    getProperties()
  }, [])

  const formik = useFormik({
    initialValues: INITIAL_VALUE,
    validationSchema: yup.object(VALIDATION_SCHEMA),
    onSubmit: async (formData) => {
      setLoading(true)
      const data = await sendFormContact(formData)

      if (data.error) {
        setLoading(false)
        return toast.warn(<ToastError />)
      }

      toast.success(<ToastSuccess />)
      setLoading(false)
      formik.resetForm()
      recaptchaRef.current.reset()
    }
  })

  return (
    <Page>
      <Seo
        description="Comuniquese con nosotros para aclarar cualquier duda que tenga sobre alguna propiedad, le responderemos a la brevedad."
        canonical="https://icasshpropiedades.cl/#/contactenos"
        title="Comuníquese con nosotros"
      />

      <section className="max-w-7xl mx-auto px-4 py-10 text-gray-600">
        <h2 className="text-3xl pb-5 font-bold border-b">Comuníquese con nosotros</h2>

        <div className="mt-5 mb-10">
          <p className="lg:w-3/6 leading-relaxed">
            Complete los datos necesarios en nuestro formulario de contacto, una vez recibido su mensaje lo
            contactaremos a la brevedad.
          </p>
        </div>

        <form onSubmit={formik.handleSubmit} noValidate>
          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label htmlFor="name">Nombre</label>
            </div>
            <div className="col-span-2">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.name}
                type="text"
                name="name"
                id="name"
                className={`${
                  formik.errors.name && formik.touched.name ? 'bg-red-50 border-red-300 focus:border-red-300' : null
                } border shadow-sm bg-white w-full p-2 rounded-lg transition-colors duration-300 focus:outline-none focus:border-gray-400`}
              />
              {formik.errors.name && formik.touched.name ? (
                <span className="text-sm text-red-400 animated fadeIn">{formik.errors.name}</span>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label htmlFor="email">Email</label>
            </div>
            <div className="col-span-2">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                type="email"
                name="email"
                id="email"
                className={`${
                  formik.errors.email && formik.touched.email ? 'bg-red-50 border-red-300 focus:border-red-300' : null
                } border shadow-sm bg-white w-full p-2 rounded-lg transition-colors duration-300 focus:outline-none focus:border-gray-400`}
              />
              {formik.errors.email && formik.touched.email ? (
                <span className="text-sm text-red-400 animated fadeIn">{formik.errors.email}</span>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label htmlFor="phone">Teléfono</label>
            </div>
            <div className="col-span-2">
              <input
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.phone}
                type="text"
                name="phone"
                id="phone"
                className={`${
                  formik.errors.phone && formik.touched.phone ? 'bg-red-50 border-red-300 focus:border-red-300' : null
                } border shadow-sm bg-white w-full p-2 rounded-lg transition-colors duration-300 focus:outline-none focus:border-gray-400`}
              />
              {formik.errors.phone && formik.touched.phone ? (
                <span className="text-sm text-red-400 animated fadeIn">{formik.errors.phone}</span>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label htmlFor="property">
                Propiedad de interés <span className="text-xs font-normal inline-block md:block">(Opcional)</span>
              </label>
            </div>
            <div className="col-span-2">
              <select
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.property}
                className="border shadow-sm bg-white w-full p-2 rounded-lg transition-colors duration-300 focus:outline-none focus:border-gray-400"
                name="property"
                id="property"
              >
                {loadingProperties && (
                  <option value="" disabled>
                    Cargando...
                  </option>
                )}
                {!propertiesTitle && !loadingProperties && (
                  <option value="" disabled>
                    Error al cargar
                  </option>
                )}
                {propertiesTitle && !loadingProperties && (
                  <>
                    <option value="" disabled>
                      - Seleccionar -
                    </option>
                    {propertiesTitle.map(
                      (property) =>
                        property.disponible && (
                          <option key={property.id} value={property.titulo}>
                            {property.titulo}
                          </option>
                        )
                    )}
                  </>
                )}
              </select>
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label htmlFor="message">Mensaje</label>
            </div>
            <div className="col-span-2">
              <textarea
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.message}
                name="message"
                id="message"
                rows="3"
                className={`${
                  formik.errors.message && formik.touched.message
                    ? 'bg-red-50 border-red-300 focus:border-red-300'
                    : null
                } resize-none shadow-sm border bg-white w-full p-2 rounded-lg transition-colors duration-300 focus:outline-none focus:border-gray-400`}
              />
              {formik.errors.message && formik.touched.message ? (
                <span className="text-sm text-red-400 animated fadeIn">{formik.errors.message}</span>
              ) : null}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mb-5">
            <div className="font-bold mb-1.5 md:mb-0">
              <label>Marque la casilla por favor</label>
            </div>
            <div className="col-span-2">
              <ReCAPTCHA
                ref={recaptchaRef}
                sitekey={process.env.REACT_APP_RECAPTCHA_KEY_V2}
                onChange={(value) => formik.setFieldValue('recaptcha', value)}
              />
              {formik.errors.recaptcha && formik.touched.recaptcha && (
                <span className="text-sm text-red-400 animated fadeIn">Por favor, marque la casilla.</span>
              )}
            </div>
          </div>

          <div className="grid grid-cols-1 md:grid-cols-3 mt-10">
            <div className="col-start-1 col-span-2 md:col-start-2">
              <button
                disabled={loading}
                type="submit"
                className={`${
                  loading && 'cursor-not-allowed'
                } bg-red-400 inline-block w-full py-3 rounded-lg text-white transition-all duration-300 hover:bg-opacity-80 focus:outline-white`}
              >
                {loading ? (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current spin inline-block mr-2 animated fadeIn"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <line x1="12" y1="6" x2="12" y2="3" />
                      <line x1="16.25" y1="7.75" x2="18.4" y2="5.6" />
                      <line x1="18" y1="12" x2="21" y2="12" />
                      <line x1="16.25" y1="16.25" x2="18.4" y2="18.4" />
                      <line x1="12" y1="18" x2="12" y2="21" />
                      <line x1="7.75" y1="16.25" x2="5.6" y2="18.4" />
                      <line x1="6" y1="12" x2="3" y2="12" />
                      <line x1="7.75" y1="7.75" x2="5.6" y2="5.6" />
                    </svg>
                    <span className="align-middle animated fadeIn">Enviando...</span>
                  </>
                ) : (
                  <>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current text-white inline-block mr-1 animated fadeIn"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="9 6 15 12 9 18" />
                    </svg>
                    <span className="align-middle animated fadeIn">Enviar</span>
                  </>
                )}
              </button>
            </div>
          </div>
        </form>
      </section>

      <section className="max-w-7xl mx-auto px-4 py-10 text-gray-600">
        <h3 className="text-2xl pb-5 font-bold">Nuestro equipo de agentes inmobiliarios que con gusto lo asesorarán</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-10 pt-5 pb-10">
          {avatars.map((avatar) => (
            <div key={avatar.id} className="text-center">
              <h4 className="text-lg font-bold mt-3">{avatar.name}</h4>
              <p className="mb-6">{avatar.title}</p>
              {avatar.rrss.map((rs) => (
                <a
                  key={rs.id}
                  href={rs.url}
                  className="transition-all duration-300 hover:text-red-400"
                  target="_blank"
                  rel="noopenner noreferrer"
                >
                  <div className="flex items-center border-b py-2">
                    <span className="mr-3">{rs.icon}</span>
                    <span className="text-sm md:text-base">{rs.name}</span>
                  </div>
                </a>
              ))}
            </div>
          ))}
        </div>
      </section>

      <section>
        <div
          dangerouslySetInnerHTML={{
            __html:
              '<iframe src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d3284.2963475078745!2d-70.978029!3d-34.5966671!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x966490409799f03d%3A0xc0cb71f011cd08b9!2sLos%20Nardos%20155%2C%20San%20Fernando%2C%20O&#39;Higgins%2C%20Chile!5e0!3m2!1ses-419!2sar!4v1614695062362!5m2!1ses-419!2sar" width="100%" height="380" style="border:0;" allowfullscreen="" loading="lazy"></iframe>'
          }}
        ></div>
      </section>
    </Page>
  )
}
