import PropTypes from 'prop-types'

import Pagination from 'react-js-pagination'

export default function Paginator({ page, totalProperties, limit, handlePageChange }) {
  return (
    <Pagination
      activePage={page}
      totalItemsCount={totalProperties}
      itemsCountPerPage={limit}
      pageRangeDisplayed={3}
      onChange={handlePageChange}
      className="pagination"
      disabledClass="text-gray-300"
      activeClass="border border-red-300 bg-red-50 px-3 py-1.5 rounded-md text-gray-600"
      itemClass="border px-3 py-1.5 bg-white mx-1 rounded-md cursor-pointer"
    />
  )
}

Paginator.propTypes = {
  page: PropTypes.number.isRequired,
  totalProperties: PropTypes.number.isRequired,
  limit: PropTypes.number.isRequired,
  handlePageChange: PropTypes.func.isRequired
}
