import { useEffect, useState, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import queryString from 'query-string'

import { Error, SearchProperties, NoSearchProperties } from '../ui/Alerts'
import Card from '../ui/Card'
import Loading from '../ui/Loading'
import Page from '../Page'
import Paginator from '../ui/Paginator'
import Seo from '../Seo'

const LIMIT = 9

export default function Buscador() {
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [page, setPage] = useState(1)
  const { location } = useHistory()
  const { termino } = queryString.parse(location.search)
  const scroll = useRef()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)

        const start = (page - 1) * LIMIT
        const url = `${process.env.REACT_APP_API_STRAPI}/propiedades?_limit=${LIMIT}&_start=${start}&titulo_contains=${termino}&_sort=createdAt:DESC`
        const resp = await fetch(url)
        const data = await resp.json()

        setLoading(false)
        setProperties(data)
      } catch (error) {
        setError(true)
        setLoading(false)
      }
    })()
  }, [termino, page])

  const handlePageChange = (nextPage) => {
    if (page === nextPage || loading) return
    setPage(nextPage)
    scroll.current.scrollIntoView()
  }

  return (
    <Page>
      <Seo
        description="Utilice nuestro buscador de propiedades para encontrar la que usted necesita precisamente."
        canonical="https://icasshpropiedades.cl/#/buscador"
        title="Buscador de propiedades"
      />

      <section className="max-w-7xl mx-auto px-4 py-10 text-gray-600">
        <h2 ref={scroll} className="text-3xl pb-5 mb-10 border-b font-bold animated fadeIn">
          Buscando por: <span className="font-normal animated fadeIn">{termino}</span>
        </h2>

        {loading ? (
          <Loading />
        ) : error ? (
          <div className="pb-10">
            <Error />
          </div>
        ) : (
          <div>
            {properties.length > 0 ? (
              <>
                <div className="pb-10">
                  <SearchProperties properties={properties.length} />
                </div>

                <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 pt-5">
                  {properties.map((property) => (
                    <Card key={property.id} property={property} />
                  ))}
                </div>
              </>
            ) : (
              <div className="pb-10">
                <NoSearchProperties />
              </div>
            )}
          </div>
        )}
      </section>

      {properties.length > 0 && !error && (
        <section className="pt-10 pb-20">
          <Paginator
            page={page}
            totalProperties={properties.length}
            limit={LIMIT}
            handlePageChange={handlePageChange}
          />
        </section>
      )}
    </Page>
  )
}
