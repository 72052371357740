import { useState, useEffect } from 'react'
import { useParams, Link } from 'react-router-dom'

import gfm from 'remark-gfm'
import ImageGallery from 'react-image-gallery'
import ReactMarkdown from 'react-markdown'

import { Error } from '../ui/Alerts'
import Loading from '../ui/Loading'
import NoImg from '../ui/NoImg'
import Page from '../Page'
import Seo from '../Seo'

export default function Propiedad() {
  const [property, setProperty] = useState({})
  const [images, setImages] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const { url } = useParams()

  useEffect(() => {
    ;(async () => {
      try {
        const urlProperty = `${process.env.REACT_APP_API_STRAPI}/propiedades/${url}`
        const resp = await fetch(urlProperty)
        const data = await resp.json()

        // Armando la galería de fotos
        const image =
          data?.imagenes &&
          data?.imagenes.length > 0 &&
          data?.imagenes
            .filter(({ formats }) => !!formats.large?.url || !!formats.medium?.url || !!formats.small?.url)
            .map(({ formats }, i) => ({
              original: formats?.medium ? formats.medium.url : formats.small.url,
              thumbnail: formats.thumbnail.url,
              thumbnailAlt: `Administración de Edificios y Condominios habitacionales - Foto ${i + 1}`,
              originalAlt: `Administración de Edificios y Condominios habitacionales - Foto ${i + 1}`
            }))

        setProperty(data)
        setImages(image)
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [url])

  const svgSize = {
    width: 22,
    height: 22
  }

  const gastosComunes = (valor) => {
    if (Number.isNaN(+valor)) return valor
    else return `$${valor}`
  }

  return (
    <Page>
      <Seo
        description={property.descripcion ? `${property.descripcion.substr(0, 120)}...` : ''}
        canonical={`https://icasshpropiedades.cl/#/propiedades/${property.url}`}
        title={property.titulo || 'Cargando propiedad...'}
      />

      {loading && (
        <div className="max-w-7xl mx-auto px-4 pb-10">
          <Loading />
        </div>
      )}
      {error && !loading && (
        <div className="max-w-7xl mx-auto px-4 pt-10 pb-20">
          <Error />
        </div>
      )}
      {property && !loading && !error && (
        <>
          <section className="max-w-7xl mx-auto px-4 py-10 text-gray-600 ">
            <span className="text-sm text-gray-500">
              Publicada el {new Date(property.createdAt).getDate()}
              {'/'}
              {new Date(property.createdAt).getMonth() + 1}
              {'/'}
              {new Date(property.createdAt).getFullYear()}
            </span>

            <h2
              className={`${property.disponible ? 'pb-5 mb-10 border-b' : ''} text-3xl mb-5 font-bold animated fadeIn`}
            >
              {property.titulo}
            </h2>

            {!property.disponible && (
              <div className="flex items-center text-gray-700 text-lg pb-5 mb-10 border-b">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current inline-block mr-2"
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 9h.01" />
                  <path d="M11 12h1v4h1" />
                </svg>
                <span>NO DISPONIBLE</span>
              </div>
            )}

            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-10">
              <div className="rounded-lg p-4 text-sm shadow-lg bg-white">
                <div className="border-b pb-3">
                  <h4 className="text-lg font-bold text-center mb-2">Valores</h4>

                  {property?.valor_venta && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                        <path d="M12 6v2m0 8v2" />
                      </svg>
                      <span className="align-middle">
                        {}
                        Venta: <strong>${property.valor_venta}</strong>
                      </span>
                    </p>
                  )}

                  {property?.valor_arriendo && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                        <path d="M12 6v2m0 8v2" />
                      </svg>
                      <span className="align-middle">
                        Arriendo: <strong>${property.valor_arriendo}</strong>
                      </span>
                    </p>
                  )}

                  {property?.valor_gastos_comunes && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="12" r="9" />
                        <path d="M14.8 9a2 2 0 0 0 -1.8 -1h-2a2 2 0 0 0 0 4h2a2 2 0 0 1 0 4h-2a2 2 0 0 1 -1.8 -1" />
                        <path d="M12 6v2m0 8v2" />
                      </svg>
                      <span className="align-middle">
                        Gastos comunes:{' '}
                        <strong>
                          {/* ${property.valor_gastos_comunes} */}
                          {gastosComunes(property.valor_gastos_comunes)}
                        </strong>
                      </span>
                    </p>
                  )}
                </div>

                <div className="mt-3">
                  <h4 className="text-lg font-bold text-center mb-2">Detalles</h4>

                  {property.ubicacion?.ubicacion && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <circle cx="12" cy="11" r="3" />
                        <path d="M17.657 16.657l-4.243 4.243a2 2 0 0 1 -2.827 0l-4.244 -4.243a8 8 0 1 1 11.314 0z" />
                      </svg>
                      <span className="align-middle">
                        Ubicación: <strong>{property.ubicacion.ubicacion}</strong>
                      </span>
                    </p>
                  )}

                  {property?.anio_construccion && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="4" y="5" width="16" height="16" rx="2" />
                        <line x1="16" y1="3" x2="16" y2="7" />
                        <line x1="8" y1="3" x2="8" y2="7" />
                        <line x1="4" y1="11" x2="20" y2="11" />
                        <line x1="11" y1="15" x2="12" y2="15" />
                        <line x1="12" y1="15" x2="12" y2="18" />
                      </svg>
                      <span className="align-middle">
                        Año de construcción: <strong>{property.anio_construccion}</strong>
                      </span>
                    </p>
                  )}

                  {property?.superficie_total && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1" />
                        <line x1="4" y1="8" x2="6" y2="8" />
                        <line x1="4" y1="12" x2="7" y2="12" />
                        <line x1="4" y1="16" x2="6" y2="16" />
                        <line x1="8" y1="4" x2="8" y2="6" />
                        <polyline points="12 4 12 7 " />
                        <polyline points="16 4 16 6 " />
                      </svg>
                      <span className="align-middle">
                        Superficie total:{' '}
                        <strong>
                          {property.superficie_total}m<sup>2</sup>
                        </strong>
                      </span>
                    </p>
                  )}

                  {property?.metros_construidos && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                        <line x1="13" y1="7" x2="13" y2="7.01" />
                        <line x1="17" y1="7" x2="17" y2="7.01" />
                        <line x1="17" y1="11" x2="17" y2="11.01" />
                        <line x1="17" y1="15" x2="17" y2="15.01" />
                      </svg>
                      <span className="align-middle">
                        Metros construidos:{' '}
                        <strong>
                          {property.metros_construidos}m<sup>2</sup>
                        </strong>
                      </span>
                    </p>
                  )}

                  {property?.habitaciones && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
                        <circle cx="7" cy="10" r="1" />
                      </svg>
                      <span className="align-middle">
                        Dormitorios: <strong>{property.habitaciones}</strong>
                      </span>
                    </p>
                  )}

                  {property?.wc && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M6.8 11a6 6 0 1 0 10.396 0l-5.197 -8l-5.2 8z" />
                      </svg>
                      <span className="align-middle">
                        Baños: <strong>{property.wc}</strong>
                      </span>
                    </p>
                  )}

                  {property?.cocinas && (
                    <p className="py-1">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block mr-2"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M4 3h8l-1 9h-6z" />
                        <path d="M7 18h2v3h-2z" />
                        <path d="M20 3v12h-5c-.023 -3.681 .184 -7.406 5 -12z" />
                        <path d="M20 15v6h-1v-3" />
                        <line x1="8" y1="12" x2="8" y2="18" />
                      </svg>
                      <span className="align-middle">
                        Cocinas: <strong>{property.cocinas}</strong>
                      </span>
                    </p>
                  )}
                </div>
              </div>

              <div className="col-span-2 mt-10 md:mt-0">
                <h4 className="text-lg font-bold text-center mb-4">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width="25"
                    height="25"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M9 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h10a2 2 0 0 0 2 -2v-12a2 2 0 0 0 -2 -2h-2" />
                    <rect x="9" y="3" width="6" height="4" rx="2" />
                    <path d="M9 14l2 2l4 -4" />
                  </svg>
                  <span className="align-middle">Descripción general</span>
                </h4>

                <ReactMarkdown className="markdown" plugins={[gfm]} children={property?.descripcion} />

                {property.video && (
                  <div className="w-full md:w-2/4 mt-10">
                    <a
                      href={property.video.url}
                      className="bg-red-400 inline-block w-full py-3 rounded-lg text-white text-center transition-all duration-300 hover:bg-opacity-80"
                      rel="noopenner noreferrer"
                      target="_blank"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-white inline-block mr-2"
                        width="30"
                        height="30"
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M7 4v16l13 -8z" />
                      </svg>
                      <span className="align-middle">Video de la propiedad</span>
                    </a>
                  </div>
                )}
              </div>
            </div>
          </section>

          <section className="max-w-7xl mx-auto px-4 pb-10 mb-10 text-gray-600 animated fadeIn">
            {images.length > 0 ? (
              <>
                <h4 className="text-lg mb-4 text-center">Galería fotográfica de la propiedad</h4>

                <ImageGallery
                  items={images}
                  additionalClass="gallery"
                  thumbnailPosition="top"
                  showPlayButton={false}
                  showBullets={true}
                />
              </>
            ) : (
              <NoImg title="Por el momento, no hay una galería fotográfica para esta propiedad" />
            )}
          </section>

          <section className="bg-gray-200 text-gray-600">
            <div className="max-w-7xl mx-auto px-4 py-10 text-center">
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10">
                <div>
                  <h5 className="font-black mb-5">¿Le interesa esta propiedad?</h5>
                  <Link
                    to="/contactenos"
                    className="bg-red-400 inline-block w-full md:w-auto py-3 md:px-12 rounded-lg text-white transition-all duration-300 hover:bg-opacity-80"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current text-white inline-block mr-1"
                      width="30"
                      height="30"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <polyline points="9 6 15 12 9 18" />
                    </svg>
                    <span className="align-middle">Contacte con un agente</span>
                  </Link>
                </div>

                <div>
                  <h5 className="font-black mb-5">Compartir el enlace de la propiedad</h5>
                  <a
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
                      window.location.origin + '/#/propiedades/' + property.url
                    )}`}
                    rel="noopenner noreferrer"
                    target="_blank"
                    title="Compartir por Facebook"
                    className=""
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current text-red-400 inline-block mx-3 duration-300 transition-all hover:opacity-80"
                      width="45"
                      height="45"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
                    </svg>
                  </a>

                  {/* https://wa.me/?text=${window.location.origin}/#/propiedades/${property.url} */}

                  <a
                    href={`https://wa.me/?text=${encodeURIComponent(
                      window.location.origin + '/#/propiedades/' + property.url
                    )}`}
                    rel="noopenner noreferrer"
                    target="_blank"
                    title="Compartir por WhatsApp"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="stroke-current text-red-400 inline-block mx-3 duration-300 transition-all hover:opacity-80"
                      width="45"
                      height="45"
                      viewBox="0 0 24 24"
                      strokeWidth="1.5"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    >
                      <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                      <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
                      <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </Page>
  )
}
