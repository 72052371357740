import { NavLink } from 'react-router-dom'

import { menuOptions, rrss } from '../db'

export default function Footer() {
  const fecha = new Date().getFullYear()

  return (
    <footer className="bg-gray-300 text-gray-700">
      <div className="max-w-7xl mx-auto px-4 py-12">
        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          <div>
            <h5 className="uppercase font-bold text-sm mb-4">Secciones</h5>
            <ul>
              {menuOptions.map((option) => (
                <NavLink exact key={option.id} to={option.url} activeClassName="text-red-500">
                  <li className="py-1 duration-300 transition-all hover:text-red-500">{option.name}</li>
                </NavLink>
              ))}
              <a href={process.env.REACT_APP_CONDOMINIO_PRO} target="_blank" rel="noopenner noreferrer nofollow">
                <li className="py-1 duration-300 transition-all hover:text-red-500">Comunidad</li>
              </a>
            </ul>
          </div>

          <div>
            <h5 className="uppercase font-bold text-sm mb-4">Redes sociales</h5>
            <ul>
              {rrss.map((rs) => (
                <a
                  key={rs.id}
                  href={rs.url}
                  className="transition-all duration-300 hover:text-red-500"
                  target="_blank"
                  rel="noopenner noreferrer nofollow"
                >
                  <li className="py-1.5">
                    {rs.icon}
                    <span className="align-middle ml-2">{rs.data}</span>
                  </li>
                </a>
              ))}
            </ul>
          </div>

          <div className="mx-auto">
            <img src="/images/logo-lg.png" alt="Logo Icassh Propiedades" width="280" height="auto" />
          </div>
        </div>

        <div className="text-center mt-10 text-gray-500">
          <p className="text-sm">
            &copy; 2020-{fecha} &bull; Sitio desarrollado por{' '}
            <a
              href="https://sebastiansanchez-dev.vercel.app/"
              target="_blank"
              rel="noopenner noreferrer"
              className="font-bold"
            >
              Sebastián Sánchez
            </a>
          </p>
        </div>
      </div>
    </footer>
  )
}
