// import { useEffect } from 'react'

import { ToastContainer, Flip } from 'material-react-toastify'
// import ReactGA from 'react-ga'

import Header from '../components/Header'
import Footer from '../components/Footer'

export default function Page({ children }) {
  // useEffect(() => {
  //   ReactGA.initialize('UA-82402252-3')
  //   ReactGA.pageview(window.location.pathname + window.location.hash)
  // }, [])

  return (
    <>
      <Header />
      <main className="bg-gray-100 animated fadeIn">{children}</main>
      <Footer />

      <ToastContainer position="top-center" autoClose={8000} hideProgressBar={false} transition={Flip} />
    </>
  )
}
