import { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'

import { NoProperties, Error } from '../components/ui/Alerts'
import Card from '../components/ui/Card'
import Loading from '../components/ui/Loading'
import Page from './Page'
import Seo from './Seo'

export default function Home() {
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)

  useEffect(() => {
    ;(async () => {
      try {
        const url = `${process.env.REACT_APP_API_STRAPI}/propiedades?_limit=6&_sort=createdAt:DESC`
        const resp = await fetch(url)
        const data = await resp.json()
        setProperties(data)
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  return (
    <Page>
      <Seo
        description="Somos agentes inmobiliarios de la VI región, con propiedades disponibles para arriendo y venta, copropiedades y mantenciones."
        canonical="https://icasshpropiedades.cl"
        title="Agentes inmobiliarios de la VI región"
      />

      <section className="max-w-7xl mx-auto px-4 py-10 mb-10 text-gray-600">
        <h2 className="text-3xl pb-5 mb-10 border-b font-bold">Últimas propiedades agregadas</h2>

        {loading && <Loading />}
        {error && !loading && <Error />}
        {!properties && !properties?.length && <NoProperties />}
        {properties && properties?.length > 0 && !loading && !error && (
          <>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10">
              {properties.map((property) => (
                <Card key={property.id} property={property} />
              ))}
            </div>

            <div className="grid grid-cols-1 md:grid-cols-3 mt-20">
              <div className="md:col-start-2 text-center">
                <Link
                  to="/propiedades"
                  className="bg-red-400 inline-block w-full py-3 rounded-lg text-white transition-all duration-300 hover:bg-opacity-80"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-white inline-block mr-1"
                    width="30"
                    height="30"
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <polyline points="9 6 15 12 9 18" />
                  </svg>
                  <span className="align-middle">Todas las propiedades</span>
                </Link>
              </div>
            </div>
          </>
        )}
      </section>

      <section className="bg-gray-200">
        <div className="max-w-7xl mx-auto px-4 py-10">
          <div className="grid grid-cols-1 md:grid-cols-3 items-center">
            <div className="text-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="stroke-current text-red-400 w-full block"
                width="120"
                height="120"
                viewBox="0 0 24 24"
                strokeWidth="1.5"
                fill="none"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                <circle cx="7" cy="5" r="2" />
                <path d="M5 22v-5l-1 -1v-4a1 1 0 0 1 1 -1h4a1 1 0 0 1 1 1v4l-1 1v5" />
                <circle cx="17" cy="5" r="2" />
                <path d="M15 22v-4h-2l2 -6a1 1 0 0 1 1 -1h2a1 1 0 0 1 1 1l2 6h-2v4" />
              </svg>
            </div>

            <div className="col-span-2 mt-5 md:mt-0">
              <h3 className="text-2xl text-gray-600 font-bold">Acerca de nosotros</h3>
              <p className="text-gray-700 leading-relaxed mt-3">
                Somos agentes inmobiliarios con inicios en el año 2013 y con proyectos de
                crecimiento en seguir ayudando a más personas a poder encontrar su propiedad
                deseada, o vender de forma segura y con asesoramiento personalizado en: arriendos,
                permutas, corretaje, tasaciones, inscripciones, regularizaciones, gestiones de
                compra y venta, y administración de propiedades y copropiedades (ley 21.442).
              </p>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}
