import { HeadProvider, Title, Meta, Link } from 'react-head'
import PropTypes from 'prop-types'

export default function Seo({ title, description, canonical }) {
  return (
    <HeadProvider>
      <Meta name="description" content={description} />
      <Link rel="canonical" href={canonical} />
      <Title>{title}</Title>
    </HeadProvider>
  )
}

Seo.propTypes = {
  description: PropTypes.string.isRequired,
  canonical: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}
