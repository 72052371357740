import { mantenciones } from '../db'
import Page from './Page'
import Seo from './Seo'

export default function Mantenciones() {
  return (
    <Page>
      <Seo
        description="Mantención integral y servicio de instalación para viviendas particulares y comunidades."
        canonical="https://icasshpropiedades.cl/#/mantenciones"
        title="Mantenciones y obras menores"
      />

      <section className="max-w-7xl mx-auto px-4 pt-10 pb-20 text-gray-600">
        <h2 className="text-3xl pb-5 mb-10 border-b font-bold">Mantenciones y obras menores</h2>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-10">
          {mantenciones.map((item) => (
            <div key={item.id} className="bg-white p-6 rounded-lg shadow">
              <div className="text-center">{item.icon}</div>
              <h4 className="text-lg text-center font-bold my-4">{item.title}</h4>
              <p>{item.description}</p>
            </div>
          ))}
        </div>
      </section>
    </Page>
  )
}
