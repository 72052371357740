import { useEffect, useState } from 'react'

import ImageGallery from 'react-image-gallery'

import { Error } from '../components/ui/Alerts'
import Loading from '../components/ui/Loading'
import NoImg from '../components/ui/NoImg'
import Page from './Page'
import Seo from './Seo'

export default function Copropiedades() {
  const [copropiedades, setCopropiedades] = useState({})
  const [loading, setLoading] = useState(true)
  const [error, setError] = useState(false)
  const [images, setImages] = useState([])

  useEffect(() => {
    ;(async () => {
      try {
        const url = `${process.env.REACT_APP_API_STRAPI}/copropiedades`
        const resp = await fetch(url)
        const data = await resp.json()

        const image =
          data?.imagenes &&
          data?.imagenes.length > 0 &&
          data?.imagenes
            .filter(({ formats }) => !!formats.large?.url || !!formats.medium?.url || !!formats.small?.url)
            .map(({ formats }, i) => ({
              original: formats?.medium ? formats.medium.url : formats.small.url,
              thumbnail: formats.thumbnail.url,
              thumbnailAlt: `Administración de Edificios y Condominios habitacionales - Foto ${i + 1}`,
              originalAlt: `Administración de Edificios y Condominios habitacionales - Foto ${i + 1}`
            }))

        setImages(image)
        setCopropiedades(data)
      } catch (error) {
        console.log({ error })
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [])

  const { imagenes, presentacion, propuesta } = copropiedades

  const svgSize = {
    width: 80,
    height: 80
  }

  return (
    <Page>
      <Seo
        description="Contamos con experiencia en la gestión, control y organización de comunidades, así como en el desarrollo inmobiliario."
        canonical="https://icasshpropiedades.cl/#/copropiedades"
        title="Administración de Edificios y Condominios"
      />

      <section className="max-w-7xl mx-auto px-4 pt-10 pb-20 text-gray-600">
        <h2 className="text-3xl pb-5 mb-10 border-b font-bold">
          Administración de Edificios y Condominios habitacionales
        </h2>

        <p className="mb-5">
          Somos competentes para enfrentar y solucionar los regulares desafíos que la vida en Comunidad origina a sus
          miembros en el cuidado y administración de sus bienes comunes. Las personas de la Administración, poseen
          experiencia en la gestión, control y organización de comunidades, así como en el desarrollo inmobiliario.
        </p>

        <p>
          Durante ese período, han recibido distintas capacitaciones, que los destacan en la gestión eficiente para el
          ejercicio de las acciones de cuidado, mantención y reparación de los bienes comunes. Nuestro interés es
          incorporar una mayor plusvalía a sus activos inmobiliarios, velando por el cumplimiento de las normas de
          resguardo e incrementando los niveles de seguridad mediante una adecuada Administración Integral de Edificios
          y Condominios.
        </p>

        {loading ? (
          <div className="pt-10">
            <Loading />
          </div>
        ) : error ? (
          <div className="pt-10">
            <Error />
          </div>
        ) : (
          <>
            <div>
              <h3 className="text-2xl py-8 font-bold mt-5">
                A continuación, lo invitamos a ver y descargar nuestros documentos
              </h3>
              <div className="grid grid-cols-1 md:grid-cols-2 gap-10 animated fadeIn">
                {presentacion ? (
                  <div className="bg-white p-6 rounded-lg shadow text-center">
                    <a
                      download
                      href={presentacion?.url}
                      target="_blank"
                      rel="noopenner noreferrer"
                      className="transition-all duration-300 hover:text-red-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <path d="M15 21h-9a3 3 0 0 1 -3 -3v-1h10v2a2 2 0 0 0 4 0v-14a2 2 0 1 1 2 2h-2m2 -4h-11a3 3 0 0 0 -3 3v11" />
                        <line x1="9" y1="7" x2="13" y2="7" />
                        <line x1="9" y1="11" x2="13" y2="11" />
                      </svg>
                      <h4 className="text-lg font-bold">Carta de presentación</h4>
                    </a>

                    <InfoFile fecha={presentacion.updatedAt} ext={presentacion.ext} size={presentacion.size} />
                  </div>
                ) : (
                  <div className="bg-white p-6 rounded-lg shadow text-center table h-full">
                    <p className="table-cell align-middle">
                      Nuestra <strong>carta de presentación</strong> aun no esta disponible para descargar.
                    </p>
                  </div>
                )}

                {propuesta ? (
                  <div className="bg-white p-6 rounded-lg shadow text-center">
                    <a
                      download
                      href={propuesta?.url}
                      target="_blank"
                      rel="noopenner noreferrer"
                      className="transition-all duration-300 hover:text-red-400"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        className="stroke-current text-red-400 inline-block"
                        width={svgSize.width}
                        height={svgSize.height}
                        viewBox="0 0 24 24"
                        strokeWidth="1.5"
                        fill="none"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      >
                        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                        <rect x="3" y="7" width="18" height="13" rx="2" />
                        <path d="M8 7v-2a2 2 0 0 1 2 -2h4a2 2 0 0 1 2 2v2" />
                        <line x1="12" y1="12" x2="12" y2="12.01" />
                        <path d="M3 13a20 20 0 0 0 18 0" />
                      </svg>
                      <h4 className="text-lg font-bold">Propuesta de trabajo</h4>
                    </a>

                    <InfoFile fecha={propuesta.updatedAt} ext={propuesta.ext} size={propuesta.size} />
                  </div>
                ) : (
                  <div className="bg-white p-6 rounded-lg shadow text-center table h-full">
                    <p className="table-cell align-middle">
                      Nuestra <strong>propuesta de trabajo</strong> aun no esta disponible para descargar.
                    </p>
                  </div>
                )}
              </div>
            </div>

            <div className="mt-10">
              <h3 className="text-2xl py-8 font-bold text-center">"Ellos han confiando en nosotros"</h3>

              {imagenes && imagenes.length > 0 ? (
                <ImageGallery
                  items={images}
                  additionalClass="gallery"
                  thumbnailPosition="top"
                  showPlayButton={false}
                  showBullets={true}
                />
              ) : (
                <NoImg title="Por el momento, no hay una galería fotográfica para esta sección" />
              )}
            </div>
          </>
        )}
      </section>
    </Page>
  )
}

function InfoFile({ fecha, ext, size }) {
  const dia = new Date(fecha).getDate()
  const mes = new Date(fecha).getMonth() + 1
  const anio = new Date(fecha).getFullYear()

  return (
    <div className="mt-3">
      <p className="text-xs text-gray-500">
        Archivo {ext} ~ Tamaño {Math.round(size)} KB
      </p>
      <p className="text-xs text-gray-500">
        Última actualización: {dia}/{mes}/{anio}
      </p>
    </div>
  )
}
