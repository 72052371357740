import { Link } from 'react-router-dom'

export const NoProperties = ({ typeSelect = null }) => {
  return (
    <div className="bg-white text-gray-700 shadow-lg p-5 rounded-lg animated fadeIn flex items-center flex-col md:flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 mb-4 md:mb-0 md:w-40 animate-pulse"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <line x1="12" y1="12" x2="12" y2="12.01" />
        <line x1="8" y1="12" x2="8" y2="12.01" />
        <line x1="16" y1="12" x2="16" y2="12.01" />
      </svg>
      {typeSelect ? (
        <p>
          Lamentablemente aun no contamos con propiedades disponibles para el tipo de filtro{' '}
          <strong>{typeSelect}</strong>. Por favor, vuelva a intentar con otra opción.
        </p>
      ) : (
        <p>
          Lamentablemente aun no contamos con propiedades disponibles para mostrar. Por favor,
          vuelva a intentar en otro momento.
        </p>
      )}
    </div>
  )
}

export const TotalProperties = ({ totalProperties = null, typeSelect = '' }) => {
  return (
    <div className="bg-white text-gray-700 p-5 rounded-lg animated fadeIn flex items-center flex-col md:flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 mb-4 md:mb-0 md:w-40 animate-pulse"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <line x1="12" y1="12" x2="12" y2="12.01" />
        <line x1="8" y1="12" x2="8" y2="12.01" />
        <line x1="16" y1="12" x2="16" y2="12.01" />
      </svg>
      {typeSelect === '' ? (
        <p>
          Contamos con un total de <strong>{totalProperties ? totalProperties : '...'}</strong>{' '}
          {totalProperties > 1 ? 'propiedades' : 'propiedad'}.
        </p>
      ) : (
        <p>
          Hay un total de <strong>{totalProperties ? totalProperties : '...'}</strong>{' '}
          {totalProperties > 1 ? 'propiedades filtradas' : 'propiedad filtrada'} por el tipo{' '}
          <strong>{typeSelect}</strong>.
        </p>
      )}
    </div>
  )
}

export const SearchProperties = ({ properties }) => {
  return (
    <div className="bg-white text-gray-700 p-5 rounded-lg animated fadeIn flex items-center flex-col md:flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 mb-4 md:mb-0 md:w-40 animate-pulse"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <line x1="12" y1="12" x2="12" y2="12.01" />
        <line x1="8" y1="12" x2="8" y2="12.01" />
        <line x1="16" y1="12" x2="16" y2="12.01" />
      </svg>
      <p>
        Se encontró en total <strong>{properties}</strong>{' '}
        {properties > 1 ? 'propiedades' : 'propiedad'} para su búsqueda.
      </p>
    </div>
  )
}

export const NoSearchProperties = () => {
  return (
    <div className="bg-white text-gray-700 shadow-lg p-5 rounded-lg animated fadeIn flex items-center flex-col md:flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 mb-4 md:mb-0 md:w-40 animate-pulse"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 20l1.3 -3.9a9 8 0 1 1 3.4 2.9l-4.7 1" />
        <line x1="12" y1="12" x2="12" y2="12.01" />
        <line x1="8" y1="12" x2="8" y2="12.01" />
        <line x1="16" y1="12" x2="16" y2="12.01" />
      </svg>
      <p>
        Lo sentimos, pero no encontramos resultados para su búsqueda. Puede intentar con otro
        termino, o ver{' '}
        <Link
          to="/propiedades"
          className="text-red-400 duration-300 transition-all hover:opacity-80"
        >
          todas nuestras propiedades
        </Link>{' '}
        disponibles.
      </p>
    </div>
  )
}

export const Error = () => {
  return (
    <div className="bg-yellow-50 shadow-lg p-5 rounded-lg animated fadeIn flex items-center flex-col md:flex-row">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-yellow-400 mb-4 md:mb-0 md:w-40 animate-pulse"
        width="50"
        height="50"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
      </svg>
      <p className="text-gray-700">
        Ocurrió un error inesperado al obtener la información solicitada. Por favor, vuelva a
        intentar recargando nuestro sitio web o intente en otro momento. Disculpe las molestias.
      </p>
    </div>
  )
}
