const svgSize = {
  width: 25,
  height: 25
}

export const menuOptions = [
  { id: 1, name: 'Inicio', url: '/' },
  { id: 2, name: 'Propiedades', url: '/propiedades' },
  { id: 3, name: 'Copropiedades', url: '/copropiedades' },
  { id: 4, name: 'Mantenciones', url: '/mantenciones' },
  { id: 5, name: 'Contacto', url: '/contactenos' }
]

export const rrss = [
  {
    id: 1,
    name: 'WhatsApp',
    data: '+56 9 5873 7966',
    url: 'https://wa.me/56958737966',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current inline-block text-red-500"
        width={svgSize.width}
        height={svgSize.height}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
        <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
      </svg>
    )
  },
  {
    id: 2,
    name: 'Email',
    data: 'contacto@icasshpropiedades.cl',
    url: 'mailto:contacto@icasshpropiedades.cl',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current inline-block text-red-500"
        width={svgSize.width}
        height={svgSize.height}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="3" y="5" width="18" height="14" rx="2" />
        <polyline points="3 7 12 13 21 7" />
      </svg>
    )
  },
  // {
  //   id: 3,
  //   name: 'Facebook',
  //   data: '/icasshpropiedades',
  //   url: 'https://www.facebook.com/icasshpropiedades/',
  //   icon: (
  //     <svg
  //       xmlns="http://www.w3.org/2000/svg"
  //       className="stroke-current inline-block text-red-500"
  //       width={svgSize.width}
  //       height={svgSize.height}
  //       viewBox="0 0 24 24"
  //       strokeWidth="1.5"
  //       fill="none"
  //       strokeLinecap="round"
  //       strokeLinejoin="round"
  //     >
  //       <path stroke="none" d="M0 0h24v24H0z" fill="none" />
  //       <path d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3" />
  //     </svg>
  //   ),
  // },
  {
    id: 4,
    name: 'Instagram',
    data: 'icasshpropiedades',
    url: 'https://www.instagram.com/icasshpropiedades/',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current inline-block text-red-500"
        width={svgSize.width}
        height={svgSize.height}
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <rect x="4" y="4" width="16" height="16" rx="4" />
        <circle cx="12" cy="12" r="3" />
        <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
      </svg>
    )
  }
]

export const mantenciones = [
  {
    id: 1,
    title: 'Por qué con nosotros',
    description:
      'Somos una empresa con experiencia en la mantención integral de viviendas tanto particulares como en comunidades, así como también en construcción de obras menores con soluciones de calidad.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 inline-block"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="9" r="6" />
        <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(-30 12 9)" />
        <polyline points="9 14.2 9 21 12 19 15 21 15 14.2" transform="rotate(30 12 9)" />
      </svg>
    )
  },
  {
    id: 2,
    title: 'Garantía de nuestro trabajo',
    description:
      'Nuestro personal está compuesto por profesionales que ayudan a conseguir el éxito y una excelente ejecución de los proyectos de nuestros clientes, manejando eficientemente los presupuestos de acuerdo a los planes de construcción de cada proyecto.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 inline-block"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M8 5h-2a2 2 0 0 0 -2 2v12a2 2 0 0 0 2 2h5.697" />
        <path d="M18 14v4h4" />
        <path d="M18 11v-4a2 2 0 0 0 -2 -2h-2" />
        <rect x="8" y="3" width="6" height="4" rx="2" />
        <circle cx="18" cy="18" r="4" />
        <path d="M8 11h4" />
        <path d="M8 15h3" />
      </svg>
    )
  },
  {
    id: 3,
    title: 'Servicio de instalaciones',
    description:
      'También realizamos el servicio de instalación de cortinas Blackout y Roller Dúo entre otros.',
    icon: (
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-red-400 inline-block"
        width="80"
        height="80"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <path d="M3 21h4l13 -13a1.5 1.5 0 0 0 -4 -4l-13 13v4" />
        <line x1="14.5" y1="5.5" x2="18.5" y2="9.5" />
        <polyline points="12 8 7 3 3 7 8 12" />
        <line x1="7" y1="8" x2="5.5" y2="9.5" />
        <polyline points="16 12 21 17 17 21 12 16" />
        <line x1="16" y1="17" x2="14.5" y2="18.5" />
      </svg>
    )
  }
]

export const avatars = [
  {
    id: 1,
    name: 'Octavio Contreras',
    title: 'Administrador general',
    rrss: [
      {
        id: 1,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        ),
        name: '+56 9 5873 7966',
        url: 'https://wa.me/56958737966'
      },
      {
        id: 2,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
          </svg>
        ),
        name: '+56 9 5873 7966',
        url: 'tel:+56958737966'
      },
      {
        id: 3,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'octaviocontreras@icasshpropiedades.cl',
        url: 'mailto:octaviocontreras@icasshpropiedades.cl'
      },
      {
        id: 4,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'propiedades.icassh@gmail.com',
        url: 'mailto:propiedades.icassh@gmail.com'
      }
    ]
  },
  {
    id: 2,
    name: 'Yasmín Sánchez',
    title: 'Secretaria',
    rrss: [
      {
        id: 1,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        ),
        name: '+56 9 4578 9324',
        url: 'https://wa.me/56945789324'
      },
      {
        id: 2,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
          </svg>
        ),
        name: '+56 9 4578 9324',
        url: 'tel:+56945789324'
      },
      {
        id: 3,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'contacto@icasshpropiedades.cl',
        url: 'mailto:contacto@icasshpropiedades.cl'
      },
      {
        id: 4,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'secretaria.icassh@gmail.com',
        url: 'mailto:secretaria.icassh@gmail.com'
      }
    ]
  },
  {
    id: 3,
    name: 'Felipe Contreras',
    title: 'Agente',
    rrss: [
      {
        id: 1,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        ),
        name: '+56 9 9039 3374',
        url: 'https://wa.me/56990393374'
      },
      {
        id: 2,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
          </svg>
        ),
        name: '+56 9 9039 3374',
        url: 'tel:+56990393374'
      },
      {
        id: 3,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'contacto@icasshpropiedades.cl',
        url: 'mailto:contacto@icasshpropiedades.cl'
      },
      {
        id: 4,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'supervisor.icassh@gmail.com',
        url: 'mailto:supervisor.icassh@gmail.com'
      }
    ]
  },
  {
    id: 4,
    name: 'Alejandra Bozo',
    title: 'Asistente',
    rrss: [
      {
        id: 1,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M3 21l1.65 -3.8a9 9 0 1 1 3.4 2.9l-5.05 .9" />
            <path d="M9 10a0.5 .5 0 0 0 1 0v-1a0.5 .5 0 0 0 -1 0v1a5 5 0 0 0 5 5h1a0.5 .5 0 0 0 0 -1h-1a0.5 .5 0 0 0 0 1" />
          </svg>
        ),
        name: '+56 9 4749 9678',
        url: 'https://wa.me/56947499678'
      },
      {
        id: 2,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <path d="M5 4h4l2 5l-2.5 1.5a11 11 0 0 0 5 5l1.5 -2.5l5 2v4a2 2 0 0 1 -2 2a16 16 0 0 1 -15 -15a2 2 0 0 1 2 -2" />
          </svg>
        ),
        name: '+56 9 4749 9678',
        url: 'tel:+56947499678'
      },
      {
        id: 3,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'contacto@icasshpropiedades.cl',
        url: 'mailto:contacto@icasshpropiedades.cl'
      },
      {
        id: 4,
        icon: (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="stroke-current text-red-400"
            width={svgSize.width}
            height={svgSize.height}
            viewBox="0 0 24 24"
            strokeWidth="1.5"
            fill="none"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
            <rect x="3" y="5" width="18" height="14" rx="2" />
            <polyline points="3 7 12 13 21 7" />
          </svg>
        ),
        name: 'asistente.icassh@gmail.com',
        url: 'mailto:asistente.icassh@gmail.com'
      }
    ]
  }
]
