import { Link } from 'react-router-dom'

export default function Card({ property }) {
  const {
    titulo,
    categorias,
    superficie_total,
    url,
    imagenes,
    habitaciones,
    wc,
    cocinas,
    anio_construccion,
    metros_construidos,
    createdAt,
    disponible
  } = property

  const svgSize = {
    width: 22,
    height: 22
  }

  return (
    <div className="animated fadeIn">
      <Link to={`/propiedades/${url}`}>
        <div className="card text-gray-600">
          <div className="overflow-hidden rounded-lg shadow-md relative">
            {!disponible && (
              <div className="flex items-center absolute right-3 top-3 bg-opacity-80 bg-white text-gray-700 z-10 rounded-lg p-2 text-xs">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="stroke-current inline-block mr-1.5"
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  strokeWidth="1.5"
                  stroke="currentColor"
                  fill="none"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                  <path d="M3 12a9 9 0 1 0 18 0a9 9 0 0 0 -18 0" />
                  <path d="M12 9h.01" />
                  <path d="M11 12h1v4h1" />
                </svg>
                <span className="font-bold">NO DISPONIBLE</span>
              </div>
            )}

            <img
              src={imagenes[0]?.formats.small.url || '/images/no-img.png'}
              className="h-56 w-full object-cover object-center opacity-90"
              alt={titulo}
            />
          </div>
          <div className="relative px-4 -mt-10">
            <div className="bg-white p-6 rounded-lg shadow-lg">
              <span className="text-sm text-gray-500">
                Publicada el {new Date(createdAt).getDate()}
                {'/'}
                {new Date(createdAt).getMonth() + 1}
                {'/'}
                {new Date(createdAt).getFullYear()}
              </span>
              <h4 className="text-lg font-bold leading-tight">{titulo}</h4>

              <div className="text-sm">
                <div className="mt-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M5 4h14a1 1 0 0 1 1 1v5a1 1 0 0 1 -1 1h-7a1 1 0 0 0 -1 1v7a1 1 0 0 1 -1 1h-5a1 1 0 0 1 -1 -1v-14a1 1 0 0 1 1 -1" />
                    <line x1="4" y1="8" x2="6" y2="8" />
                    <line x1="4" y1="12" x2="7" y2="12" />
                    <line x1="4" y1="16" x2="6" y2="16" />
                    <line x1="8" y1="4" x2="8" y2="6" />
                    <polyline points="12 4 12 7 " />
                    <polyline points="16 4 16 6 " />
                  </svg>
                  <span className="align-middle">
                    Superficie total:{' '}
                    <strong>
                      {superficie_total || ' - '}m<sup>2</sup>
                    </strong>
                  </span>
                </div>

                <div className="border-b py-2">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M8 9l5 5v7h-5v-4m0 4h-5v-7l5 -5m1 1v-6a1 1 0 0 1 1 -1h10a1 1 0 0 1 1 1v17h-8" />
                    <line x1="13" y1="7" x2="13" y2="7.01" />
                    <line x1="17" y1="7" x2="17" y2="7.01" />
                    <line x1="17" y1="11" x2="17" y2="11.01" />
                    <line x1="17" y1="15" x2="17" y2="15.01" />
                  </svg>
                  <span className="align-middle">
                    Metros construidos:{' '}
                    <strong>
                      {metros_construidos || ' - '}m<sup>2</sup>
                    </strong>
                  </span>
                </div>

                <div className="pt-2 pb-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <rect x="4" y="5" width="16" height="16" rx="2" />
                    <line x1="16" y1="3" x2="16" y2="7" />
                    <line x1="8" y1="3" x2="8" y2="7" />
                    <line x1="4" y1="11" x2="20" y2="11" />
                    <line x1="11" y1="15" x2="12" y2="15" />
                    <line x1="12" y1="15" x2="12" y2="18" />
                  </svg>
                  <span className="align-middle">
                    Año de construcción: <strong>{anio_construccion || ' - '}</strong>
                  </span>
                </div>

                <div className="pb-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M3 7v11m0 -4h18m0 4v-8a2 2 0 0 0 -2 -2h-8v6" />
                    <circle cx="7" cy="10" r="1" />
                  </svg>
                  <span className="align-middle">
                    Dormitorios: <strong>{habitaciones || ' - '}</strong>
                  </span>
                </div>

                <div className="pb-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M6.8 11a6 6 0 1 0 10.396 0l-5.197 -8l-5.2 8z" />
                  </svg>
                  <span className="align-middle">
                    Baños: <strong>{wc || ' - '}</strong>
                  </span>
                </div>

                <div className="pb-2 border-b">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="stroke-current text-red-400 inline-block mr-2"
                    width={svgSize.width}
                    height={svgSize.height}
                    viewBox="0 0 24 24"
                    strokeWidth="1.5"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  >
                    <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                    <path d="M4 3h8l-1 9h-6z" />
                    <path d="M7 18h2v3h-2z" />
                    <path d="M20 3v12h-5c-.023 -3.681 .184 -7.406 5 -12z" />
                    <path d="M20 15v6h-1v-3" />
                    <line x1="8" y1="12" x2="8" y2="18" />
                  </svg>
                  <span className="align-middle">
                    Cocinas: <strong>{cocinas || ' - '}</strong>
                  </span>
                </div>
              </div>

              <div className="mt-5 text-center">
                {categorias.length > 0 ? (
                  categorias.map((categoria) => (
                    <span key={categoria.id} className="text-sm bg-red-400 text-white py-1.5 px-3 rounded-full mr-1.5">
                      {categoria?.categoria}
                    </span>
                  ))
                ) : (
                  <span className="text-sm bg-gray-400 text-white py-1.5 px-3 rounded-full mr-1.5">Sin categorías</span>
                )}
              </div>
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}
