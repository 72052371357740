import { useFormik } from 'formik'
import { useHistory } from 'react-router-dom'
import * as yup from 'yup'

export default function Buscador() {
  const history = useHistory()

  const formik = useFormik({
    initialValues: { search: '' },
    validationSchema: yup.object({
      search: yup.string().trim().required(true)
    }),
    onSubmit: ({ search }) => {
      formik.resetForm()
      history.push(`/buscador?termino=${search}`)
    }
  })

  return (
    <form onSubmit={formik.handleSubmit} className="relative">
      <label htmlFor="search" className="text-gray-50 font-bold text-shadow text-lg">
        Buscador de propiedades
      </label>
      <input
        onChange={formik.handleChange}
        value={formik.values.search}
        type="text"
        name="search"
        id="search"
        className={`${
          formik.errors.search && 'bg-red-50 border-red-400 focus:border-red-400'
        } text-gray-600 w-full py-4 pl-4 pr-16 mt-1 rounded-lg shadow-2xl text-lg border-2 border-transparent duration-300 transition-colors focus:outline-none focus:border-gray-400`}
        placeholder="Ej. casa en arriendo"
      />

      <button type="submit" className="submit" title="Buscar"></button>
    </form>
  )
}
