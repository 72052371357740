import Buscar from './ui/Buscar'
import Navbar from './ui/Navbar'

export default function Header() {
  return (
    <header className="header">
      <Navbar />

      <div className="max-w-7xl mx-auto px-4 py-20 md:py-24 lg:py-32">
        <div>
          <h1 className="font-bold text-shadow text-white">
            <p className="text-4xl md:text-5xl mb-1.5">Agentes inmobiliarios</p>
            <p className="text-2xl md:text-3xl">de la VI región</p>
          </h1>
        </div>

        <div className="mt-8 md:w-6/12">
          <Buscar />
        </div>
      </div>
    </header>
  )
}
