import { useEffect, useState, useRef } from 'react'

import { getTypePropertyApi, getTotalPropertiesApi } from '../../helpers/fetch'
import { Error, NoProperties, TotalProperties } from '../ui/Alerts'
import Card from '../ui/Card'
import Loading from '../ui/Loading'
import Page from '../Page'
import Paginator from '../ui/Paginator'
import Seo from '../Seo'

// Limite para la consulta y la paginación
const LIMIT = 9

export default function Propiedades() {
  const [properties, setProperties] = useState([])
  const [loading, setLoading] = useState(false)
  const [loadingTypes, setLoadingTypes] = useState(false)
  const [error, setError] = useState(false)
  const [typeProperty, setTypeProperty] = useState([])
  const [totalProperties, setTotalProperties] = useState(0)
  const [typeSelect, setTypeSelect] = useState('')
  const [page, setPage] = useState(1)
  const scroll = useRef()

  useEffect(() => {
    ;(async () => {
      try {
        setLoading(true)
        const start = (page - 1) * LIMIT
        const url = `${process.env.REACT_APP_API_STRAPI}/propiedades?_limit=${LIMIT}&_start=${start}&tipo_de_propiedad.tipo_contains=${typeSelect}&_sort=createdAt:DESC`
        const resp = await fetch(url)
        const data = await resp.json()
        setProperties(data)
      } catch {
        setError(true)
      } finally {
        setLoading(false)
      }
    })()
  }, [page, typeSelect])

  // Obtener los tipos de propiedades
  useEffect(() => {
    ;(async () => {
      setLoadingTypes(true)
      const type = await getTypePropertyApi()
      setTypeProperty(type)
      setLoadingTypes(false)
    })()
  }, [])

  // Obtener el total de propiedades filtradas por tipo
  useEffect(() => {
    ;(async () => {
      const total = await getTotalPropertiesApi(typeSelect)
      setTotalProperties(total)
    })()
  }, [typeSelect])

  const handleChangeSelect = ({ target }) => {
    setTypeSelect(target.value)
    setPage(1) // Reestablecer la posición de la paginación
  }

  const handlePageChange = (nextPage) => {
    if (page === nextPage || loading) return
    setPage(nextPage)
    scroll.current.scrollIntoView()
  }

  return (
    <Page>
      <Seo
        description="Conozca todas nuestras propiedades disponibles como casas, departamentos, terrenos y mucho más."
        canonical="https://icasshpropiedades.cl/#/propiedades"
        title="Propiedades disponibles"
      />

      <section className="max-w-7xl mx-auto px-4 py-10 text-gray-600">
        <div className="flex justify-between pb-5 flex-col md:flex-row border-b">
          <div>
            <h2 ref={scroll} className="text-3xl font-bold">
              Todas las propiedades disponibles
            </h2>
          </div>

          <div className="text-right mt-5 md:mt-0 animated fadeIn ">
            <label htmlFor="type">Filtrar por tipo:</label>
            <select
              onChange={handleChangeSelect}
              value={typeSelect}
              name="typeSelect"
              id="type"
              className="bg-white p-2 ml-3 mt-1 lg:mt-0 rounded-lg border transition-colors duration-300 focus:outline-none focus:border-gray-400"
            >
              {loadingTypes && (
                <option value="" disabled>
                  Cargando...
                </option>
              )}
              {!typeProperty && !loadingTypes && (
                <option value="" disabled>
                  Error al cargar
                </option>
              )}
              {typeProperty && !loadingTypes && (
                <>
                  <option value="">Todas</option>
                  <option disabled></option>
                  {typeProperty.map((type) => (
                    <option key={type.id} value={type.tipo}>
                      {type.tipo}
                    </option>
                  ))}
                </>
              )}
            </select>
          </div>
        </div>
      </section>

      <section className="max-w-7xl mx-auto px-4 pb-10 text-gray-600">
        {loading && <Loading />}
        {error && !loading && (
          <div className="pb-10">
            <Error />
          </div>
        )}
        {properties && properties?.length === 0 && !loading && !error && (
          <div className="pb-10">
            {typeSelect === '' ? <NoProperties /> : <NoProperties typeSelect={typeSelect} />}
          </div>
        )}
        {properties && properties?.length > 0 && !loading && !error && (
          <>
            <div className="pb-10">
              {typeSelect === '' ? (
                <TotalProperties totalProperties={totalProperties} />
              ) : (
                <TotalProperties totalProperties={totalProperties} typeSelect={typeSelect} />
              )}
            </div>

            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-10 pt-5">
              {properties.map((property) => (
                <Card key={property.id} property={property} />
              ))}
            </div>

            <section className="pt-10 pb-20">
              <Paginator
                page={page}
                totalProperties={totalProperties}
                limit={LIMIT}
                handlePageChange={handlePageChange}
              />
            </section>
          </>
        )}
      </section>
    </Page>
  )
}
