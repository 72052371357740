export const ToastError = () => {
  return (
    <div className="flex items-center p-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-white mr-3"
        width="60"
        height="60"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <line x1="12" y1="8" x2="12" y2="12" />
        <line x1="12" y1="16" x2="12.01" y2="16" />
      </svg>
      <span className="text-base leading-tight">
        Error al enviar el formulario, vuelva a intentar por favor.
      </span>
    </div>
  )
}

export const ToastSuccess = () => {
  return (
    <div className="flex items-center p-1">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        className="stroke-current text-white mr-3"
        width="60"
        height="60"
        viewBox="0 0 24 24"
        strokeWidth="1.5"
        fill="none"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path stroke="none" d="M0 0h24v24H0z" fill="none" />
        <circle cx="12" cy="12" r="9" />
        <path d="M9 12l2 2l4 -4" />
      </svg>
      <span className="text-base leading-tight">
        Formulario enviado exitosamente, pronto le responderemos.
      </span>
    </div>
  )
}
