export const getTotalPropertiesApi = async (typeSelect) => {
  try {
    const url = `${process.env.REACT_APP_API_STRAPI}/propiedades?tipo_de_propiedad.tipo_contains=${typeSelect}`
    const resp = await fetch(url)
    const data = await resp.json()

    return data.length
  } catch (error) {
    return null
  }
}

export const getTypePropertyApi = async () => {
  try {
    const url = `${process.env.REACT_APP_API_STRAPI}/tipos-de-propiedades?_sort=tipo:ASC`
    const resp = await fetch(url)
    const data = await resp.json()

    return data
  } catch (error) {
    return null
  }
}

export const getPropertiesTitleApi = async () => {
  try {
    const url = `${process.env.REACT_APP_API_STRAPI}/propiedades?_sort=titulo:ASC`
    const resp = await fetch(url)
    const data = await resp.json()

    return data
  } catch (error) {
    return null
  }
}

export const sendFormContact = async (formData) => {
  try {
    const url = `${process.env.REACT_APP_API_CONTACT}/icasshpropiedades`
    const resp = await fetch(url, {
      method: 'POST',
      body: JSON.stringify(formData),
      headers: {
        'Content-Type': 'application/json'
      }
    })
    const data = await resp.json()

    return data
  } catch (error) {
    console.log({ error })
    return null
  }
}
