import { Switch, Route, HashRouter, Redirect } from 'react-router-dom'

import Buscador from '../components/propiedades/Buscador'
import Contacto from '../components/Contacto'
import Copropiedades from '../components/Copropiedades'
import Home from '../components/Home'
import Mantenciones from '../components/Mantenciones'
import Propiedad from '../components/propiedades/Propiedad'
import Propiedades from '../components/propiedades/Propiedades'
import ScrollToTop from '../components/ui/ScrollToTop'

export default function AppRouter() {
  return (
    <HashRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/propiedades" component={Propiedades} />
        <Route exact path="/propiedades/:url" component={Propiedad} />
        <Route excat path="/buscador" component={Buscador} />
        <Route exact path="/copropiedades" component={Copropiedades} />
        <Route exact path="/mantenciones" component={Mantenciones} />
        <Route excat path="/contactenos" component={Contacto} />
        <Redirect to="/" />
      </Switch>
    </HashRouter>
  )
}
