import PropTypes from 'prop-types'

export default function NoImg({ title }) {
  return (
    <div className="text-center animated fadeIn">
      <h4 className="text-lg mb-4">{title}</h4>
      <img
        className="w-auto inline-block rounded-lg"
        src="/images/no-img.png"
        alt="Galería no disponible"
      />
    </div>
  )
}

NoImg.propTypes = {
  title: PropTypes.string.isRequired
}
